@import '../../styles/customMediaQueries.css';

.landingPageHeroSectionMobile {
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}

.landingPageHeroSectionDesktop {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}
