@import '../../../styles/customMediaQueries.css';
@import '../../../styles/gridDefaults.scss';

$placeholderGrey: #4a4a4e;

.frame {
  width: 100%;

  .overlapGroupWrapper {
    background-color: var(--colorWhite);
    height: 100%;
    max-height: 600px;
    width: 100%;

    .overlapGroup {
      background-image: url('../assets/leechy-home-hero-background.png');
      background-position: 50% 50%;
      background-size: cover;
      height: 100%;

      .searchForm {
        background-color: #1f1f1f80;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 20px 8px 30px 8px;

        .watermark {
          width: 348px;
          margin: 0 auto;
          img {
            width: 100px;
            margin: 0 auto;
          }
        }

        .sloganWrapper {
          color: var(--colorWhite);
          font-family: 'Inter', Helvetica;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: normal;
          margin: 10px auto 0 auto;
          width: 100%;
          max-width: 348px;

          p {
            color: var(--colorWhite);
            font-family: 'Inter', Helvetica;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: normal;
            text-align: left;
          }
        }

        .locationWrapper {
          background-color: var(--colorWhite);
          border-radius: 6px;
          height: 48px;
          margin: 10px auto 0 auto;
          width: 100%;
          max-width: 348px;
          cursor: pointer;

          .locationPlaceholder {
            color: $placeholderGrey;
            font-family: 'Inter', Helvetica;
            font-size: 16px;
            font-weight: 400;
            left: 33px;
            letter-spacing: 0;
            line-height: normal;
            position: relative;
            top: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;
          }

          .icon {
            height: 19px;
            left: 11px;
            position: relative;
            top: -6px;
            width: 14px;
          }
        }

        .datesWrapper {
          background-color: var(--colorWhite);
          border-radius: 6px;
          height: 48px;
          margin: 10px auto 0 auto;
          width: 100%;
          max-width: 348px;
          cursor: pointer;

          .datesPlaceholder {
            color: $placeholderGrey;
            font-family: 'Inter', Helvetica;
            font-size: 16px;
            font-weight: 400;
            left: 33px;
            letter-spacing: 0;
            line-height: normal;
            position: relative;
            top: 14px;
            white-space: nowrap;
          }

          .icon {
            height: 17px;
            left: 11px;
            position: relative;
            top: -7.5px;
            width: 15px;
          }
        }

        .categoryDropdownWrapper {
          background-image: url(https://c.animaapp.com/x4t9OtXc/img/rectangle-3.png);
          background-size: 100% 100%;
          height: 48px;
          margin: 10px auto 0 auto;
          width: 100%;
          max-width: 348px;
          cursor: pointer;

          .categoryDropdown {
            box-shadow: unset;
            background-color: unset;
            border: unset;
            height: 48px;
          }

          .categoryPlaceholder {
            color: $placeholderGrey;
            font-family: 'Inter', Helvetica;
            font-size: 16px;
            font-weight: 400;
            left: 11px;
            letter-spacing: 0;
            line-height: normal;
            position: relative;
            top: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 300px;
          }
        }

        .searchButton {
          background-color: var(--marketplaceColorDark);
          border-radius: 50px;
          box-shadow: 0px 4px 4px #00000040;
          height: 42px;
          margin: 10px auto 0 auto;
          width: 100%;
          max-width: 348px;
          cursor: pointer;
          &:hover {
            background-color: var(--marketplaceColor);
          }

          .searchButtonText {
            color: var(--colorWhite);
            text-shadow: -0.5px 0 var(--colorGrey700), 0 0.5px var(--colorGrey700),
              0.5px 0 var(--colorGrey700), 0 -0.5px var(--colorGrey700);
            font-family: 'Inter', Helvetica;
            font-size: 16px;
            font-weight: 800;
            left: 146px;
            letter-spacing: 0;
            line-height: normal;
            position: relative;
            top: 11px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.locationModalForm {
  margin-top: 30px;
  .locationAutocompleteInputIcon {
    display: none;
  }
  .predictionsRoot {
    position: absolute;
    width: 100%;
    top: 36px;
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);

    @media (--viewportMedium) {
      top: 40px;
    }
  }
  .locationAddress,
  .building {
    flex-shrink: 0;
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}
