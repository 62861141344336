.ionContentContainer {
  // min-height: 100vh;
  // min-height: calc(100vh - var(--topbarHeightDesktop));
  width: 100%;
}

.iconSpinnerContainer {
  margin: 0 auto;
  text-align: center;
  height: auto;
  padding-top: 10px;
  position: relative;
}

.iconSpinner {
  width: 28px;
  height: 28px;
  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}
